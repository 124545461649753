import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import useContentfulImage from "../utils/useContentfulImage";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <Img
        fluid={useContentfulImage(node.data.target.fields.file["ja-JP"].url)}
        alt={
          node.data.target.fields.description
            ? node.data.target.fields.description["ja-JP"]
            : node.data.target.fields.title["ja-JP"]
        }
      />
    ),
  },
};

const blogPost = ({ data, location }) => {
  const title = data.contentfulBlogPost.title;
  const publishDate = data.contentfulBlogPost.publishDate;
  const publishDateJP = data.contentfulBlogPost.publishDateJP;
  const category = data.contentfulBlogPost.category;
  const eyecatch = data.contentfulBlogPost.eyecatch;
  const content = data.contentfulBlogPost.content.json;

  return (
    <div>
      <SEO
        pagetitle={title}
        pagedesc={`${documentToPlainTextString(
          data.contentfulBlogPost.content.json
        ).slice(0, 70)}`}
        pagepath={location.pathname}
      />
      <Layout>
        <div className="blogPost">
          <article className="blogContent">
            <h1>{title}</h1>
            <div className="eyecatch">
              <figure>
                <Img fluid={eyecatch.fluid} alt={eyecatch.description} />
              </figure>
            </div>
            <div className="blogContainer">
              <aside className="blogInfo">
                <time dateTime={publishDate}>{publishDateJP}</time>
                <div className="cat">
                  <ul>
                    {category.map((cat) => (
                      <li className={cat.categorySlug} key={cat.id}>
                        {cat.category}
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
              <section className="blogBody">
                {documentToReactComponents(content, options)}
              </section>
            </div>
          </article>
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      title
      publishDateJP: publishDate(formatString: "YYYY年M月D日")
      publishDate
      category {
        category
        categorySlug
        id
      }
      eyecatch {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp
        }
        description
      }
      content {
        json
      }
    }
  }
`;

export default blogPost;
